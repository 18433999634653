
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React from 'react';
import logoText from '../Images/Color_logo.png';
import { Container, Button, Row, Col } from 'react-bootstrap';

function MyNav() {
  return (
    <Navbar expand="lg" data-bs-theme="dark">
    <Container fluid>
      <Navbar.Brand href="/" style={{fontSize:"1.5rem", padding:"1rem"}}>
      <img
            src={logoText}
            width="200"
            height="65"
            className="d-inline-block align-center"
            alt="DB Outdoor Services"
          />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className='justify-content-center flex-grow-1' style={{marginLeft: "0.5rem"}}>
          <Nav.Link id="link" style={{width: '14%', marginRight:"0.5rem", marginLeft: "0.5rem", fontSize:"1.2rem", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: ".3rem"}} href="/">Home</Nav.Link>
          <Nav.Link id="link" style={{width: '14%', marginRight:"0.5rem", marginLeft: "0.5rem", fontSize:"1.2rem", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: ".3rem"}} href="about">About</Nav.Link>
          <Nav.Link id="link" style={{width: '14%', marginRight:"0.5rem", marginLeft: "0.5rem", fontSize:"1.2rem", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: ".3rem"}} href="services">Services</Nav.Link>
          <Nav.Link id="link" style={{width: '14%', marginRight:"0.5rem", marginLeft: "0.5rem", fontSize:"1.2rem", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: ".3rem"}} href="ourwork">Our Work</Nav.Link>
          <Nav.Link id="link" style={{width: '14%', marginRight:"0.5rem", marginLeft: "0.5rem", fontSize:"1.2rem", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: ".3rem"}} href="contact">Contact</Nav.Link>
        </Nav>
        <Row>
          <Col>
            <Button id="FreeEstimateButton" style={{position: "relative", marginRight: "8rem", alignItems: 'center',  display: 'inline-flex', textAlign: "center", justifyContent: 'center'}} variant="outline-warning" href="contact">Get a Free Estimate</Button>
          </Col>
        </Row>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}

export default MyNav;